export class ShoeList {
  static men = {
    shoeList: [

      { id: "1", brand: "Adidas", name: "Adidas Men's Adizero Ubersonic 3 Tennis Shoes Signal Pink and Black",
      size: ["6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "14"], color: "Red", price: "129.95",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/92437-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/92437-zoomThumb.png",
      zoomFull: "../../../assets/images/92437-DEFAULT-l.jpg" },

      { id: "2", brand: "Adidas", name: "Adidas Men's SoleMatch Bounce Tennis Shoes Tech Indigo and Signal Green",
      size: ["7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13"], color: "Green", price: "119.95",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/92315-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/92315-zoomThumb.png",
      zoomFull: "../../../assets/images/92315-DEFAULT-l.jpg" },

      { id: "3", brand: "Adidas", name: "Adidas Men's SoleCourt Boost Tennis Shoes White and Legacy Green",
      size: ["6.5", "10.5", "15"], color: "White", price: "109.97",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/87670-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/87670-zoomThumb.png",
      zoomFull: "../../../assets/images/87670-DEFAULT-l.jpg" },

      { id: "4", brand: "Adidas", name: "Adidas Men's Stycon Tennis Shoes Gray Two and White",
      size: ["7", "7.5", "10", "11.5", "12", "14"], color: "Gray", price: "96.97",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/87673-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/87673-zoomThumb.png",
      zoomFull: "../../../assets/images/87673-DEFAULT-l.jpg" },

      { id: "5", brand: "Adidas", name: "Adidas Men's Adizero Ubersonic 3 Tennis Shoes Team Royal Blue and White",
      size: ["7", "8"], color: "Blue", price: "89.97",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/87653-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/87653-zoomThumb.png",
      zoomFull: "../../../assets/images/87653-DEFAULT-l.jpg" },

      { id: "6", brand: "Adidas", name: "Adidas Men's Adizero Ubersonic 3 Tennis Shoes Signal Green and Core Black",
      size: ["10", "12"], color: "Green", price: "89.97",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/90919-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/90919-zoomThumb.png",
      zoomFull: "../../../assets/images/90919-DEFAULT-l.jpg" },

      { id: "7", brand: "Adidas", name: "Adidas Men's Adizero Club 2 Tennis Shoes Signal Green and White",
      size: ["6.5", "7.5", "8", "8.5", "10.5", "12.5", "13"], color: "Green", price: "79.95",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/92300-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/92300-zoomThumb.png",
      zoomFull: "../../../assets/images/92300-DEFAULT-l.jpg" },

      { id: "8", brand: "Adidas", name: "Adidas Men's CourtJam Bounce Tennis Shoes Dash Green and Signal Coral",
      size: ["12", "12.5", "15"], color: "Red", price: "63.75",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/87660-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/87660-zoomThumb.png",
      zoomFull: "../../../assets/images/87660-DEFAULT-l.jpg" },

      { id: "9", brand: "Adidas", name: "Adidas Men's CourtJam Bounce Tennis Shoes Gray Two and Signal Coral",
      size: ["6.5", "15"], color: "Gray", price: "63.75",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/87662-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/87662-zoomThumb.png",
      zoomFull: "../../../assets/images/87662-DEFAULT-l.jpg" },

      { id: "10", brand: "Adidas", name: "Adidas Men's Torsion Comp Lifestyle Shoes White",
      size: ["7", "7.5", "8", "8.5", "9.5", "10", "11", "11.5", "12", "12.5", "13"], color: "White", price: "59.95",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/87105-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/87105-zoomThumb.png",
      zoomFull: "../../../assets/images/87105-DEFAULT-l.jpg" },

      { id: "11", brand: "Adidas", name: "Adidas Men's GameCourt Tennis Shoes White and Dove Gray",
      size: ["7", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12.5", "13", "15"], color: "White", price: "48.75",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/87668-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/87668-zoomThumb.png",
      zoomFull: "../../../assets/images/87668-DEFAULT-l.jpg" },

      { id: "12", brand: "Adidas", name: "Adidas Men's GameCourt Tennis Shoes Core Black and White",
      size: ["12.5"], color: "Black", price: "48.75",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/87666-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/87666-zoomThumb.png",
      zoomFull: "../../../assets/images/87666-DEFAULT-l.jpg" },

      { id: "13", brand: "Asics", name: "Asics Men's Court FF 2 Tennis Shoes Classic Red and White",
      size: ["6", "7", "7.5", "8", "9", "9.5", "11", "11.5", "12", "14", "15"], color: "Red", price: "124.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/88190-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88190-zoomThumb.png",
      zoomFull: "../../../assets/images/88190-DEFAULT-l.jpg" },

      { id: "14", brand: "Asics", name: "Asics Men's Court FF Novak Tennis Shoes Kale and White",
      size: ["6", "7", "8", "10.5", "11", "11.5", "13", "15"], color: "Green", price: "124.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/88192-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88192-zoomThumb.png",
      zoomFull: "../../../assets/images/88192-DEFAULT-l.jpg" },

      { id: "15", brand: "Asics", name: "Asics Men's Solution Speed FF Tennis Shoes White and Silver",
      size: ["6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "14", "15"], color: "White", price: "119.95",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/79622-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/79622-zoomThumb.png",
      zoomFull: "../../../assets/images/79622-DEFAULT-l.jpg" },

      { id: "16", brand: "Asics", name: "Asics Men's Court Speed FF Tennis Shoes Mako Blue",
      size: ["6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "14", "15"], color: "Blue", price: "104.95",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/92905-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/92905-zoomThumb.png",
      zoomFull: "../../../assets/images/92905-DEFAULT-l.jpg" },

      { id: "17", brand: "Asics", name: "Asics Men's Court Speed FF Tennis Shoes White and Pure Silver",
      size: ["6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "14", "15"], color: "White", price: "104.95",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/88193-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88193-zoomThumb.png",
      zoomFull: "../../../assets/images/88193-DEFAULT-l.jpg" },

      { id: "18", brand: "Asics", name: "Asics Men's GEL-Resolution 8 Tennis Shoes Black and White",
      size: ["6", "6.5", "7", "7.5"], color: "Black", price: "99.94",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/88186-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88186-zoomThumb.png",
      zoomFull: "../../../assets/images/88186-DEFAULT-l.jpg" },

      { id: "19", brand: "Asics", name: "Asics Men's Gel-Resolution 7 Tennis Shoes White and Silver",
      size: ["6"], color: "White", price: "84.95",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/64428-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/64428-zoomThumb.png",
      zoomFull: "../../../assets/images/64428-DEFAULT-l.jpg" },

      { id: "20", brand: "Asics", name: "Asics Men's GEL-Game 7 Tennis Shoes Mako Blue and Pure Silver",
      size: ["6", "7"], color: "Blue", price: "74.95",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/92883-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/92883-zoomThumb.png",
      zoomFull: "../../../assets/images/92883-DEFAULT-l.jpg" },

      { id: "21", brand: "Asics", name: "Asics Men's Gel-Game 7 Novak Tennis Shoes Classic Red and Pure Silver",
      size: ["6"], color: "Red", price: "69.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/92167-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/92167-zoomThumb.png",
      zoomFull: "../../../assets/images/92167-DEFAULT-l.jpg" },

      { id: "22", brand: "Asics", name: "Asics Men's GEL-Dedicate 6 Tennis Shoes ASICS Blue and White",
      size: ["8"], color: "Blue", price: "59.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/88183-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88183-zoomThumb.png",
      zoomFull: "../../../assets/images/88183-DEFAULT-l.jpg" },

      { id: "23", brand: "Asics", name: "Asics Men's Upcourt 3 Squash Shoes Black and Pure Silver",
      size: ["8.5", "9.5", "11.5", "12", "13", "14"], color: "Black", price: "49.99",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/88220-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88220-zoomThumb.png",
      zoomFull: "../../../assets/images/88220-DEFAULT-l.jpg" },

      { id: "24", brand: "Asics", name: "Asics Men's GEL-Dedicate 6 Tennis Shoes White and Silver",
      size: ["7.5"], color: "White", price: "49.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/84439-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/84439-zoomThumb.png",
      zoomFull: "../../../assets/images/84439-DEFAULT-l.jpg" },

      { id: "25", brand: "Fila", name: "Fila Men's Axilus 2 Energized Tennis Shoes Gold Fusion and Black",
      size: ["7", "7.5", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "14"], color: "Yellow", price: "110.00",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/92955-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/92955-zoomThumb.png",
      zoomFull: "../../../assets/images/92955-DEFAULT-l.jpg" },

      { id: "26", brand: "Fila", name: "Fila Men's Axilus 2 Energized Tennis Shoes White",
      size: ["7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "15"], color: "White", price: "110.00",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/90685-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/90685-zoomThumb.png",
      zoomFull: "../../../assets/images/90685-DEFAULT-l.jpg" },

      { id: "27", brand: "Fila", name: "Fila Men's Axilus 2 Energized Tennis Shoes Pacific and White",
      size: ["7", "7.5", "8", "8.5", "9", "10", "12", "13"], color: "Green", price: "82.50",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/90684-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/90684-zoomThumb.png",
      zoomFull: "../../../assets/images/90684-DEFAULT-l.jpg" },

      { id: "28", brand: "Fila", name: "Fila Men's Axilus 2 Energized Tennis Shoes Acid Lime and Blueprint",
      size: ["10", "10.5", "11", "11.5", "12"], color: "Green", price: "82.50",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/91136-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/91136-zoomThumb.png",
      zoomFull: "../../../assets/images/91136-DEFAULT-l.jpg" },

      { id: "29", brand: "Fila", name: "Fila Men's Original Fitness Stripe Shoes White and Navy",
      size: ["7.5", "8", "8.5", "9"], color: "White", price: "56.00",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/86815-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/86815-zoomThumb.png",
      zoomFull: "../../../assets/images/86815-DEFAULT-l.jpg" },

      { id: "30", brand: "Fila", name: "Fila Men's Original Fitness Graphic Shoes White and Navy",
      size: ["7", "7.5", "8", "8.5", "9", "9.5", "10", "12"], color: "White", price: "52.00",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/86816-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/86816-zoomThumb.png",
      zoomFull: "../../../assets/images/86816-DEFAULT-l.jpg" },

      { id: "31", brand: "New Balance", name: "New Balance Men's 996v4 D Width Tennis Shoes White and Iridescent",
      size: ["7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "14", "15"], color: "White", price: "99.00",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/93275-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/93275-zoomThumb.png",
      zoomFull: "../../../assets/images/93275-DEFAULT-l.jpg" },

      { id: "32", brand: "New Balance", name: "New Balance Men's Fresh Foam LAV D Width Tennis Shoes Scarlet and White",
      size: ["11.5", "15"], color: "Red", price: "89.94",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/85648-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/85648-zoomThumb.png",
      zoomFull: "../../../assets/images/85648-DEFAULT-l.jpg" },

      { id: "33", brand: "New Balance", name: "New Balance Men's FuelCell 996v4 D Width Tennis Shoes Sulphur Yellow and Lemon Slush",
      size: ["7.5", "9.5", "11.5", "13"], color: "Yellow", price: "89.00",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/88051-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88051-zoomThumb.png",
      zoomFull: "../../../assets/images/88051-DEFAULT-l.jpg" },

      { id: "34", brand: "New Balance", name: "New Balance Men's 896v3 D Width Tennis Shoes Gray and Pigment",
      size: ["7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "14", "15"], color: "Gray", price: "74.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/85644-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/85644-zoomThumb.png",
      zoomFull: "../../../assets/images/85644-DEFAULT-l.jpg" },

      { id: "35", brand: "New Balance", name: "New Balance Men's 896v3 D Width Tennis Shoes Black and Energy Red",
      size: ["9.5", "10.5", "12.5", "13"], color: "Black", price: "74.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/85642-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/85642-zoomThumb.png",
      zoomFull: "../../../assets/images/85642-DEFAULT-l.jpg" },

      { id: "36", brand: "New Balance", name: "New Balance Men's 696v4 2E Width Tennis Shoes White and Pigment",
      size: ["8", "8.5", "9.5", "10", "10.5", "11", "11.5", "12"], color: "White", price: "54.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/88060-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88060-zoomThumb.png",
      zoomFull: "../../../assets/images/88060-DEFAULT-l.jpg" },

      { id: "37", brand: "Nike", name: "Nike Men's Air Zoom Vapor X Knit Tennis Shoes Laser Crimson and Gym Red",
      size: ["7", "7.5", "8", "8.5", "9", "9.5", "10", "11", "11.5", "13", "15"], color: "Red", price: "127.50",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/90285-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/90285-zoomThumb.png",
      zoomFull: "../../../assets/images/90285-DEFAULT-l.jpg" },

      { id: "38", brand: "Nike", name: "Nike Men's Air Zoom Vapor Cage 4 Tennis Shoes Black and Laser Crimson",
      size: ["7.5", "9", "10", "11", "11.5", "12", "12.5"], color: "Purple", price: "112.50",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/90335-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/90335-zoomThumb.png",
      zoomFull: "../../../assets/images/90335-DEFAULT-l.jpg" },

      { id: "39", brand: "Nike", name: "Nike Men's Air Zoom Vapor Cage 4 Tennis Shoes White and Black",
      size: ["6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "14", "15"], color: "White", price: "112.50",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/90300-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/90300-zoomThumb.png",
      zoomFull: "../../../assets/images/90300-DEFAULT-l.jpg" },

      { id: "40", brand: "Nike", name: "Nike Men's Air Zoom Zero Tennis Shoes White and Laser Crimson",
      size: ["6.5", "7", "8", "8.5", "9", "10", "10.5", "11", "11.5", "12", "14", "15"], color: "White", price: "97.50",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/88166-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88166-zoomThumb.png",
      zoomFull: "../../../assets/images/88166-DEFAULT-l.jpg" },

      { id: "41", brand: "Nike", name: "Nike Men's Air Zoom Zero Tennis Shoes White and Black",
      size: ["6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "14", "15"], color: "White", price: "97.50",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/88167-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88167-zoomThumb.png",
      zoomFull: "../../../assets/images/88167-DEFAULT-l.jpg" },

      { id: "42", brand: "Nike", name: "Nike Men's Zoom Cage 3 Tennis Shoes White and Game Royal",
      size: ["6", "6.5"], color: "White", price: "84.50",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/87239-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/87239-zoomThumb.png",
      zoomFull: "../../../assets/images/87239-DEFAULT-l.jpg" },

      { id: "43", brand: "Nike", name: "Nike Men's Air Zoom Prestige Tennis Shoes Light Smoke Gray and Blue Hero",
      size: ["6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "14"], color: "Gray", price: "75.00",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/88168-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88168-zoomThumb.png",
      zoomFull: "../../../assets/images/88168-DEFAULT-l.jpg" },

      { id: "44", brand: "Nike", name: "Nike Men's Air Zoom Prestige Tennis Shoes Black and White",
      size: ["6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "14", "15"], color: "Black", price: "75.00",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/88169-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88169-zoomThumb.png",
      zoomFull: "../../../assets/images/88169-DEFAULT-l.jpg" },

      { id: "45", brand: "Nike", name: "Nike Men's Air Max Wildcard Tennis Shoes Light Smoke Gray and Blue Hero",
      size: ["6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "13", "14", "15"], color: "Gray", price: "67.50",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/91545-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/91545-zoomThumb.png",
      zoomFull: "../../../assets/images/91545-DEFAULT-l.jpg" },

      { id: "46", brand: "Nike", name: "Nike Men's Air Max Wildcard Tennis Shoes White and Royal Pulse",
      size: ["6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "14", "15"], color: "White", price: "67.50",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/91519-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/91519-zoomThumb.png",
      zoomFull: "../../../assets/images/91519-DEFAULT-l.jpg" },

      { id: "47", brand: "Nike", name: "Nike Men's Air Max Wildcard Tennis Shoes Black and Phantom",
      size: ["6", "10"], color: "Black", price: "58.50",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/82205-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/82205-zoomThumb.png",
      zoomFull: "../../../assets/images/82205-DEFAULT-l.jpg" },

      { id: "48", brand: "Nike", name: "Nike Men's Air Zoom Resistance Tennis Shoes White and University Gold",
      size: ["6"], color: "White", price: "48.00",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/83638-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/83638-zoomThumb.png",
      zoomFull: "../../../assets/images/83638-DEFAULT-l.jpg" }

    ]
  }
  static women = {
    shoeList: [

      { id: "1", brand: "Adidas", name: "Adidas Women's Adizero Ubersonic 3 Tokyo Tennis Shoes Signal Pink and Black",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5"], color: "Red", price: "129.95",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/92335-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/92335-zoomThumb.png",
      zoomFull: "../../../assets/images/92335-DEFAULT-l.jpg" },

      { id: "2", brand: "Adidas", name: "Adidas Women's SoleCourt Boost Parley Tennis Shoes Easy Blue and White",
      size: ["5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5"], color: "Blue", price: "109.97",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/90897-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/90897-zoomThumb.png",
      zoomFull: "../../../assets/images/90897-DEFAULT-l.jpg" },

      { id: "3", brand: "Adidas", name: "Adidas Women's Stella Court Tennis Shoes Triple White",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "11", "11.5"], color: "White", price: "99.94",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/87640-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/87640-zoomThumb.png",
      zoomFull: "../../../assets/images/87640-DEFAULT-l.jpg" },

      { id: "4", brand: "Adidas", name: "Adidas Women's Stella Court Tennis Shoes Black and Gray",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "9", "10.5", "11"], color: "Black", price: "99.94",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/90712-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/90712-zoomThumb.png",
      zoomFull: "../../../assets/images/90712-DEFAULT-l.jpg" },

      { id: "5", brand: "Adidas", name: "Adidas Women's Stella McCartney Barricade Boost Tennis Shoes Eggshell Gray and Aero Lm",
      size: ["5"], color: "Gray", price: "89.00",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/72806-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/72806-zoomThumb.png",
      zoomFull: "../../../assets/images/72806-DEFAULT-l.jpg" },

      { id: "6", brand: "Adidas", name: "Adidas Women's Adizero Ubersonic 3 Tennis Shoes Bright Yellow and Core Black",
      size: ["11.5"], color: "Yellow", price: "84.96",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/67938-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/67938-zoomThumb.png",
      zoomFull: "../../../assets/images/67938-DEFAULT-l.jpg" },

      { id: "7", brand: "Adidas", name: "Adidas Women's SoleCourt Boost Tennis Shoes Shock Cyan and White",
      size: ["5", "11.5"], color: "Blue", price: "79.00",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/81567-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/81567-zoomThumb.png",
      zoomFull: "../../../assets/images/81567-DEFAULT-l.jpg" },

      { id: "8", brand: "Adidas", name: "Adidas Women's Stella McCartney Court Boost Tennis Shoes White and Active Red",
      size: ["11.5"], color: "White", price: "79.00",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/84892-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/84892-zoomThumb.png",
      zoomFull: "../../../assets/images/84892-DEFAULT-l.jpg" },

      { id: "9", brand: "Adidas", name: "Adidas Women's SoleMatch Bounce Tennis Shoes Purple Tint and Core Black",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11.5"], color: "Purple", price: "77.97",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/87690-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/87690-zoomThumb.png",
      zoomFull: "../../../assets/images/87690-DEFAULT-l.jpg" },

      { id: "10", brand: "Adidas", name: "Adidas Women's SoleMatch Bounce Tennis Shoes White and Glow Green",
      size: ["6.5", "8", "9.5", "10", "11", "11.5"], color: "Green", price: "69.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/85014-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/85014-zoomThumb.png",
      zoomFull: "../../../assets/images/85014-DEFAULT-l.jpg" },

      { id: "11", brand: "Adidas", name: "Adidas Women's CourtJam Bounce Multicourt Tennis Shoes True Pink and Active Purple",
      size: ["8"], color: "Purple", price: "54.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/81560-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/81560-zoomThumb.png",
      zoomFull: "../../../assets/images/81560-DEFAULT-l.jpg" },

      { id: "12", brand: "Adidas", name: "Adidas Women's GameCourt Tennis Shoes White and Blue Tint",
      size: ["11", "11.5"], color: "White", price: "44.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/84890-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/84890-zoomThumb.png",
      zoomFull: "../../../assets/images/84890-DEFAULT-l.jpg" },

      { id: "13", brand: "Asics", name: "Asics Women's Solution Speed FF Tennis Shoes Techno Cyan and White",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12"], color: "Green", price: "124.95",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/92878-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/92878-zoomThumb.png",
      zoomFull: "../../../assets/images/92878-DEFAULT-l.jpg" },

      { id: "14", brand: "Asics", name: "Asics Women's Solution Speed FF Tennis Shoes White and Silver",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12"], color: "White", price: "119.95",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/79627-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/79627-zoomThumb.png",
      zoomFull: "../../../assets/images/79627-DEFAULT-l.jpg" },

      { id: "15", brand: "Asics", name: "Asics Women's Court FF 2 Tennis Shoes Laser Pink and White",
      size: ["5", "8"], color: "Red", price: "109.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/84450-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/84450-zoomThumb.png",
      zoomFull: "../../../assets/images/84450-DEFAULT-l.jpg" },

      { id: "16", brand: "Asics", name: "Asics Women's GEL-Resolution 8 Retro Tokyo Tennis Shoes White and Electric Blue",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12"], color: "White", price: "99.94",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/88217-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88217-zoomThumb.png",
      zoomFull: "../../../assets/images/88217-DEFAULT-l.jpg" },

      { id: "17", brand: "Asics", name: "Asics Women's Gel-Resolution 7 Tennis Shoes White and Silver",
      size: ["5", "12"], color: "White", price: "84.95",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/64458-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/64458-zoomThumb.png",
      zoomFull: "../../../assets/images/64458-DEFAULT-l.jpg" },

      { id: "18", brand: "Asics", name: "Asics Women's GEL-Challenger 12 Tennis Shoes Cotton Candy and Peacoat",
      size: ["5", "5.5", "6", "7", "7.5", "9.5", "10.5", "11.5"], color: "Purple", price: "79.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/88209-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88209-zoomThumb.png",
      zoomFull: "../../../assets/images/88209-DEFAULT-l.jpg" },

      { id: "19", brand: "Asics", name: "Asics Women's Gel-Challenger 11 Tennis Shoes Electric Blue and White",
      size: ["5", "5.5"], color: "Blue", price: "72.21",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/79641-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/79641-zoomThumb.png",
      zoomFull: "../../../assets/images/79641-DEFAULT-l.jpg" },

      { id: "20", brand: "Asics", name: "Asics Women's GEL-Game 7 Tennis Shoes Laser Pink and White",
      size: ["5", "5.5", "6", "6.5", "7", "11.5", "12"], color: "Red", price: "64.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/84414-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/84414-zoomThumb.png",
      zoomFull: "../../../assets/images/84414-DEFAULT-l.jpg" },

      { id: "21", brand: "Asics", name: "Asics Women's GEL-Dedicate 6 Tennis Shoes Cotton Candy and White",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "11.5", "12"], color: "Purple", price: "59.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/88211-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88211-zoomThumb.png",
      zoomFull: "../../../assets/images/88211-DEFAULT-l.jpg" },

      { id: "22", brand: "Asics", name: "Asics Women's GEL-Rocket 9 Squash Shoes Black and White",
      size: ["5", "5.5", "6.5", "7", "7.5", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12"], color: "Black", price: "56.00",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/84445-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/84445-zoomThumb.png",
      zoomFull: "../../../assets/images/84445-DEFAULT-l.jpg" },

      { id: "23", brand: "Asics", name: "Asics Women's Gel-Game 7 Tennis Shoes Blue Coast and Silver",
      size: ["5"], color: "Blue", price: "54.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/82428-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/82428-zoomThumb.png",
      zoomFull: "../../../assets/images/82428-DEFAULT-l.jpg" },

      { id: "24", brand: "Asics", name: "Asics Women's Gel-Game 7 Tennis Shoes White and Silver",
      size: ["12"], color: "White", price: "48.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/81981-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/81981-zoomThumb.png",
      zoomFull: "../../../assets/images/81981-DEFAULT-l.jpg" },

      { id: "25", brand: "Fila", name: "Fila Women's Axilus 2 Energized Tennis Shoes White",
      size: ["6", "7", "10", "11"], color: "White", price: "110.00",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/90682-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/90682-zoomThumb.png",
      zoomFull: "../../../assets/images/90682-DEFAULT-l.jpg" },

      { id: "26", brand: "Fila", name: "Fila Women's Axilus 2 Energized Tennis Shoes Green Ash and Amparo Blue",
      size: ["6", "7", "7.5", "8", "8.5", "9.5", "10.5", "11"], color: "Green", price: "82.50",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/90683-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/90683-zoomThumb.png",
      zoomFull: "../../../assets/images/90683-DEFAULT-l.jpg" },

      { id: "27", brand: "Fila", name: "Fila Women's Original Fitness Embroidery Shoes White",
      size: ["7.5", "8.5", "9"], color: "White", price: "60.00",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/86819-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/86819-zoomThumb.png",
      zoomFull: "../../../assets/images/86819-DEFAULT-l.jpg" },

      { id: "28", brand: "Fila", name: "Fila Women's Original Fitness Signature 2 Shoes White and Gold",
      size: ["6", "6.5", "9.5", "11"], color: "White", price: "52.00",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/86820-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/86820-zoomThumb.png",
      zoomFull: "../../../assets/images/86820-DEFAULT-l.jpg" },

      { id: "29", brand: "New Balance", name: "New Balance Women's Fresh Foam LAV B Width Tennis Shoes Guava and White",
      size: ["5", "5.5", "6.5", "7", "8", "9.5", "10", "11"], color: "Red", price: "99.00",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/88144-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88144-zoomThumb.png",
      zoomFull: "../../../assets/images/88144-DEFAULT-l.jpg" },

      { id: "30", brand: "New Balance", name: "New Balance Women's Fresh Foam LAV B Width Tennis Shoes Lemon Slush and White",
      size: ["5", "6", "7", "8.5", "9.5", "10", "10.5", "11"], color: "Yellow", price: "99.00",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/88146-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88146-zoomThumb.png",
      zoomFull: "../../../assets/images/88146-DEFAULT-l.jpg" },

      { id: "31", brand: "New Balance", name: "New Balance Women's FuelCell 996v4 B Width Tennis Shoes Bali Blue and Vision Blue",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11"], color: "Blue", price: "89.00",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/91963-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/91963-zoomThumb.png",
      zoomFull: "../../../assets/images/91963-DEFAULT-l.jpg" },

      { id: "32", brand: "New Balance", name: "New Balance Women's 896v3 B Width Tennis Shoes Peony and Vivid Coral",
      size: ["5", "5.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5"], color: "Purple", price: "74.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/85556-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/85556-zoomThumb.png",
      zoomFull: "../../../assets/images/85556-DEFAULT-l.jpg" },

      { id: "33", brand: "New Balance", name: "New Balance Women's 696v4 B Width Tennis Shoes White and Pigment",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11"], color: "White", price: "54.94",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/88075-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/88075-zoomThumb.png",
      zoomFull: "../../../assets/images/88075-DEFAULT-l.jpg" },

      { id: "34", brand: "New Balance", name: "New Balance Women's 996v3 B Width Tennis Shoes Black and Bayside",
      size: ["5", "5.5", "6"], color: "Black", price: "49.94",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/86878-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/86878-zoomThumb.png",
      zoomFull: "../../../assets/images/86878-DEFAULT-l.jpg" },

      { id: "35", brand: "Nike", name: "Nike Women's Air Zoom Vapor X Knit Tennis Shoes Laser Crimson and Pink",
      size: ["5.5", "6", "6.5", "7", "8", "8.5", "9.5", "10", "10.5", "11", "11.5", "12"], color: "Red", price: "127.50",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/91531-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/91531-zoomThumb.png",
      zoomFull: "../../../assets/images/91531-DEFAULT-l.jpg" },

      { id: "36", brand: "Nike", name: "Nike Women's Air Zoom Vapor X Tennis Shoes Royal Pulse and Obsidian",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12"], color: "Blue", price: "112.50",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/91505-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/91505-zoomThumb.png",
      zoomFull: "../../../assets/images/91505-DEFAULT-l.jpg" },

      { id: "37", brand: "Nike", name: "Nike Women's Air Zoom Vapor X Knit Tennis Shoes Opti Yellow and Bright Citron",
      size: ["5", "6"], color: "Yellow", price: "110.50",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/90293-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/90293-zoomThumb.png",
      zoomFull: "../../../assets/images/90293-DEFAULT-l.jpg" },

      { id: "38", brand: "Nike", name: "Nike Women's Zoom Cage 3 Tennis Shoes Bordeaux and White",
      size: ["5"], color: "Purple", price: "91.00",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/87242-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/87242-zoomThumb.png",
      zoomFull: "../../../assets/images/87242-DEFAULT-l.jpg" },

      { id: "39", brand: "Nike", name: "Nike Women's Zoom Cage 3 Tennis Shoes Pure Platinum and Racer Blue",
      size: ["5", "6"], color: "Gray", price: "91.00",
      outsoleWarranty: "Yes",
      thumbURL: "../../assets/images/84793-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/84793-zoomThumb.png",
      zoomFull: "../../../assets/images/84793-DEFAULT-l.jpg" },

      { id: "40", brand: "Nike", name: "Nike Women's Flare 2 HC Tennis Shoes Royal Pulse and Obsidian",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "8.5", "9", "10", "10.5", "11", "11.5"], color: "Blue", price: "82.50",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/91449-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/91449-zoomThumb.png",
      zoomFull: "../../../assets/images/91449-DEFAULT-l.jpg" },

      { id: "41", brand: "Nike", name: "Nike Women's Flare 2 HC Tennis Shoes Laser Crimson and Pink",
      size: ["5.5", "6", "6.5", "7", "7.5", "8.5"], color: "Red", price: "82.50",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/91537-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/91537-zoomThumb.png",
      zoomFull: "../../../assets/images/91537-DEFAULT-l.jpg" },

      { id: "42", brand: "Nike", name: "Nike Women's Air Zoom Ultra Tennis Shoes White and Mega Blue",
      size: ["5"], color: "White", price: "75.00",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/68492-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/68492-zoomThumb.png",
      zoomFull: "../../../assets/images/68492-DEFAULT-l.jpg" },

      { id: "43", brand: "Nike", name: "Nike Women's Air Zoom Prestige Tennis Shoes White and Laser Fuchsia",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12"], color: "White", price: "75.00",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/92542-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/92542-zoomThumb.png",
      zoomFull: "../../../assets/images/92542-DEFAULT-l.jpg" },

      { id: "44", brand: "Nike", name: "Nike Women's Air Max Vapor Wing MS Tennis Shoes White",
      size: ["5", "5.5", "6", "6.5", "7", "7.5", "9", "10", "11", "11.5", "12"], color: "White", price: "60.00",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/90345-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/90345-zoomThumb.png",
      zoomFull: "../../../assets/images/90345-DEFAULT-l.jpg" },

      { id: "45", brand: "Nike", name: "Nike Women's Air Max Wildcard Tennis Shoes Bordeaux and White",
      size: ["5", "5.5", "11.5"], color: "Purple", price: "58.50",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/87284-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/87284-zoomThumb.png",
      zoomFull: "../../../assets/images/87284-DEFAULT-l.jpg" },

      { id: "46", brand: "Nike", name: "Nike Women's Air Max Wildcard Tennis Shoes Metallic Blue Dusk and Bright Mango",
      size: ["5"], color: "Gray", price: "49.97",
      outsoleWarranty: "No",
      thumbURL: "../../assets/images/83616-DEFAULT-m.jpg",
      zoomThumb: "../../../assets/images/83616-zoomThumb.png",
      zoomFull: "../../../assets/images/83616-DEFAULT-l.jpg" }

    ]
  }
}
