export class ItemList {
  static items = {
    itemList: [
      { id: "1", name: "Dunlop ATP Extra Duty Tennis Ball 24 Can Case",
      type: "balls", price: "89.99",
      thumbURL: "../../assets/images/DATPXD.jpg",
      zoomThumb: "../../../assets/images/DATPXD-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/DATPXD-zoomFull.jpeg" },

      { id: "2", name: "Dunlop Stage 2 Ball Orange 72 Ball Case",
      type: "balls", price: "74.95",
      thumbURL: "../../assets/images/DS2OR24.jpg",
      zoomThumb: "../../../assets/images/DS2OR24-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/DS2OR24-zoomFull.jpeg" },

      { id: "3", name: "Dunlop Grand Prix Regular Duty Tennis Balls 24 Can Case",
      type: "balls", price: "69.60",
      thumbURL: "../../assets/images/DGPAS.jpg",
      zoomThumb: "../../../assets/images/DGPAS-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/DGPAS-zoomFull.jpeg" },

      { id: "4", name: "Dunlop Champ Hard Court XD Tennis Balls 24 Can Case",
      type: "balls", price: "54.95",
      thumbURL: "../../assets/images/DCHTBC.jpg",
      zoomThumb: "../../../assets/images/DCHTBC-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/DCHTBC-zoomFull.jpeg" },

      { id: "5", name: "Dunlop ATP Championship XD Tennis Ball Single Can",
      type: "balls", price: "3.49",
      thumbURL: "../../assets/images/DATPCHXDR.jpg",
      zoomThumb: "../../../assets/images/DATPCHXDR-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/DATPCHXDR-zoomFull.jpeg" },

      { id: "6", name: "Pro Penn Marathon Extra Duty Tennis Balls 24 Can Case",
      type: "balls", price: "78.99",
      thumbURL: "../../assets/images/PPMXD.jpg",
      zoomThumb: "../../../assets/images/PPMXD-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/PPMXD-zoomFull.jpeg" },

      { id: "7", name: "Penn Tour Regular Duty Tennis Ball 24 Can Case",
      type: "balls", price: "69.99",
      thumbURL: "../../assets/images/TOURDC.jpg",
      zoomThumb: "../../../assets/images/TOURDC-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/TOURDC-zoomFull.jpeg" },

      { id: "8", name: "Penn Championship Regular Duty Tennis Balls 24 Can Case",
      type: "balls", price: "59.95",
      thumbURL: "../../assets/images/PCHAMPRD.jpg",
      zoomThumb: "../../../assets/images/PCHAMPRD-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/PCHAMPRD-zoomFull.jpeg" },

      { id: "9", name: "Penn Championship XD Tennis Ball Single Can",
      type: "balls", price: "3.49",
      thumbURL: "../../assets/images/PCHAMPXDC.jpg",
      zoomThumb: "../../../assets/images/PCHAMPXDC-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/PCHAMPXDC-zoomFull.jpeg" },

      { id: "10", name: "Wilson Triniti All Court Tennis Ball 24 Can Case",
      type: "balls", price: "99.99",
      thumbURL: "../../assets/images/WTRINBC.jpg",
      zoomThumb: "../../../assets/images/WTRINBC-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/WTRINBC-zoomFull.jpeg" },

      { id: "11", name: "Wilson US Open XD Tennis 4-Ball x 18 Can Case",
      type: "balls", price: "89.99",
      thumbURL: "../../assets/images/WUSOXD4.jpg",
      zoomThumb: "../../../assets/images/WUSOXD4-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/WUSOXD4-zoomFull.jpeg" },

      { id: "12", name: "Wilson Champ Regular Duty Tennis Ball 24 Can Case",
      type: "balls", price: "69.99",
      thumbURL: "../../assets/images/WCHRDC.jpg",
      zoomThumb: "../../../assets/images/WCHRDC-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/WCHRDC-zoomFull.jpeg" },

      { id: "13", name: "Wilson Championship XD Tennis Ball Single Can",
      type: "balls", price: "3.49",
      thumbURL: "../../assets/images/WCHXDCAN.jpg",
      zoomThumb: "../../../assets/images/WCHXDCAN-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/WCHXDCAN-zoomFull.jpeg" },

      { id: "14", name: "BNP Paribas Open 2020 Players Towel Yellow/Purple",
      type: "towels", price: "30.00",
      thumbURL: "../../assets/images/BPO20TPY.jpg",
      zoomThumb: "../../../assets/images/BPO20TPY-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/BPO20TPY-zoomFull.jpeg" },

      { id: "15", name: "BNP Paribas Open 2020 Players Towel Royal/Green",
      type: "towels", price: "30.00",
      thumbURL: "../../assets/images/BPO20TRL.jpg",
      zoomThumb: "../../../assets/images/BPO20TRL-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/BPO20TRL-zoomFull.jpeg" },

      { id: "16", name: "Babolat Medium Towel Black/White",
      type: "towels", price: "20.00",
      thumbURL: "../../assets/images/B20MTBW.jpg",
      zoomThumb: "../../../assets/images/B20MTBW-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/B20MTBW-zoomFull.jpeg" },

      { id: "17", name: "Babolat Medium Towel Blue/Navy",
      type: "towels", price: "20.00",
      thumbURL: "../../assets/images/B20MTBN.jpg",
      zoomThumb: "../../../assets/images/B20MTBN-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/B20MTBN-zoomFull.jpeg" },

      { id: "18", name: "Nike Fundamental Towel White Medium",
      type: "towels", price: "20.00",
      thumbURL: "../../assets/images/NFMTW.jpg",
      zoomThumb: "../../../assets/images/NFMTW-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/NFMTW-zoomFull.jpeg" },

      { id: "19", name: "Nike Fundamental Towel Black Medium",
      type: "towels", price: "20.00",
      thumbURL: "../../assets/images/NFMTB.jpg",
      zoomThumb: "../../../assets/images/NFMTB-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/NFMTB-zoomFull.jpeg" },

      { id: "20", name: "Tourna Sport Towel",
      type: "towels", price: "6.00",
      thumbURL: "../../assets/images/TSTL.jpg",
      zoomThumb: "../../../assets/images/TSTL-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/TSTL-zoomFull.jpeg" },

      { id: "21", name: "Prince DuraPro+ 3 Pack Overgrip Pink/Yellow/Blue",
      type: "grips", price: "4.00",
      thumbURL: "../../assets/images/PDPP3PO-WPB.jpg",
      zoomThumb: "../../../assets/images/PDPP3PO-WPB-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/PDPP3PO-WPB-zoomFull.jpeg" },

      { id: "22", name: "Prince DuraPro+ 3 Pack Overgrip White",
      type: "grips", price: "4.00",
      thumbURL: "../../assets/images/PDPP3PO-WH.jpg",
      zoomThumb: "../../../assets/images/PDPP3PO-WH-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/PDPP3PO-WH-zoomFull.jpeg" },

      { id: "23", name: "Prince TackyPro 12 Pack Overgrip White",
      type: "grips", price: "17.00",
      thumbURL: "../../assets/images/PTP12POW.jpg",
      zoomThumb: "../../../assets/images/PTP12POW-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/PTP12POW-zoomFull.jpeg" },

      { id: "24", name: "Wilson Pro Overgrip 3 Pack Pink/White/Green",
      type: "grips", price: "6.49",
      thumbURL: "../../assets/images/PROGC-ML.jpg",
      zoomThumb: "../../../assets/images/PROGC-ML-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/PROGC-ML-zoomFull.jpeg" },

      { id: "25", name: "Wilson Pro Overgrip 3 Pack White",
      type: "grips", price: "6.49",
      thumbURL: "../../assets/images/PROGC-WH.jpg",
      zoomThumb: "../../../assets/images/PROGC-WH-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/PROGC-WH-zoomFull.jpeg" },

      { id: "26", name: "Wilson Pro Overgrip 12 Grip Pack White",
      type: "grips", price: "18.95",
      thumbURL: "../../assets/images/PRO12.jpg",
      zoomThumb: "../../../assets/images/PRO12-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/PRO12-zoomFull.jpeg" },

      { id: "27", name: "Wilson Pro Overgrip 30 Grip Reel White",
      type: "grips", price: "37.95",
      thumbURL: "../../assets/images/WPRO30G-WH.jpg",
      zoomThumb: "../../../assets/images/WPRO30G-WH-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/WPRO30G-WH-zoomFull.jpeg" },

      { id: "28", name: "Luxilon Elite Dry Overgrip 3 Pack",
      type: "grips", price: "6.49",
      thumbURL: "../../assets/images/LEDG.jpg",
      zoomThumb: "../../../assets/images/LEDG-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/LEDG-zoomFull.jpeg" },

      { id: "29", name: "Luxilon Elite Dry Overgrip 12 Pack",
      type: "grips", price: "18.95",
      thumbURL: "../../assets/images/LEDG12.jpg",
      zoomThumb: "../../../assets/images/LEDG12-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/LEDG12-zoomFull.jpeg" },

      { id: "30", name: "Luxilon Elite Dry Overgrip 30 Pack",
      type: "grips", price: "42.95",
      thumbURL: "../../assets/images/LEDG30.jpg",
      zoomThumb: "../../../assets/images/LEDG30-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/LEDG30-zoomFull.jpeg" },

      { id: "31", name: "Babolat Flag Damp Yellow/Black",
      type: "dampeners", price: "5.95",
      thumbURL: "../../assets/images/BVDF-YE.jpg",
      zoomThumb: "../../../assets/images/BVDF-YE-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/BVDF-YE-zoomFull.jpeg" },

      { id: "32", name: "Babolat Custom Damp White/Blue",
      type: "dampeners", price: "7.95",
      thumbURL: "../../assets/images/BCD2WB.jpg",
      zoomThumb: "../../../assets/images/BCD2WB-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/BCD2WB-zoomFull.jpeg" },

      { id: "33", name: "Gamma String Things Dampener 2 pack Panda",
      type: "dampeners", price: "4.99",
      thumbURL: "../../assets/images/GSTD2PPANDA.jpg",
      zoomThumb: "../../../assets/images/GSTD2PPANDA-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/GSTD2PPANDA-zoomFull.jpeg" },

      { id: "34", name: "Gamma String Things Dampener 2 pack Fish",
      type: "dampeners", price: "4.99",
      thumbURL: "../../assets/images/GSTD2P.jpg",
      zoomThumb: "../../../assets/images/GSTD2P-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/GSTD2P-zoomFull.jpeg" },

      { id: "35", name: "Gamma Shockbuster Vibration Dampener",
      type: "dampeners", price: "3.29",
      thumbURL: "../../assets/images/SB-YE.jpg",
      zoomThumb: "../../../assets/images/SB-YE-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/SB-YE-zoomFull.jpeg" },

      { id: "36", name: "Head Pro Damp - Pink",
      type: "dampeners", price: "3.99",
      thumbURL: "../../assets/images/HPDPK.jpg",
      zoomThumb: "../../../assets/images/HPDPK-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/HPDPK-zoomFull.jpeg" },

      { id: "37", name: "Head Djokovic Dampener",
      type: "dampeners", price: "3.99",
      thumbURL: "../../assets/images/HDD2.jpg",
      zoomThumb: "../../../assets/images/HDD2-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/HDD2-zoomFull.jpeg" },

      { id: "38", name: "Head Smartsorb Vibration Dampener",
      type: "dampeners", price: "2.99",
      thumbURL: "../../assets/images/HSZVIB.jpg",
      zoomThumb: "../../../assets/images/HSZVIB-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/HSZVIB-zoomFull.jpeg" },

      { id: "39", name: "Luxilon Legacy Dampener",
      type: "dampeners", price: "5.49",
      thumbURL: "../../assets/images/LUXLEGD.jpg",
      zoomThumb: "../../../assets/images/LUXLEGD-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/LUXLEGD-zoomFull.jpeg" },

      { id: "40", name: "Luxilon LXN Dampener",
      type: "dampeners", price: "5.49",
      thumbURL: "../../assets/images/LUXD.jpg",
      zoomThumb: "../../../assets/images/LUXD-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/LUXD-zoomFull.jpeg" },

      { id: "41", name: "Supergoop! Antioxidant-Infused SPF50 Sunscreen Mist 6oz",
      type: "sunscreen", price: "21.00",
      thumbURL: "../../assets/images/SAI50SM6.jpg",
      zoomThumb: "../../../assets/images/SAI50SM6-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/SAI50SM6-zoomFull.jpeg" },

      { id: "42", name: "Supergoop! Everyday SPF 50 Sunscreen 2.4 fl. oz.",
      type: "sunscreen", price: "22.00",
      thumbURL: "../../assets/images/SE50S2.jpg",
      zoomThumb: "../../../assets/images/SE50S2-zoomThumb.jpeg",
      zoomFull: "../../../assets/images/SE50S2-zoomFull.jpeg" }
    ]
  }
}
